import React from 'react';
import { graphql } from 'gatsby';
import { Logo } from '../../components/Logo';
import { SEO } from '../../components/SEO';
import { ContactTemplate } from '../../components/ContactTemplate';

export default function contact({ data }) {
  return (
    <>
      <SEO title="Rumblewood commercial contact" />
      <Logo color="black" />
      <ContactTemplate data={data.contact} />
    </>
  );
}

export const query = graphql`
  {
    contact: sanityCommercialContact {
      title
      _rawContent
    }
  }
`;
